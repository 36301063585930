var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "main",
    [
      _c("nav", { attrs: { "aria-label": "breadcrumb" } }, [
        _c("ol", { staticClass: "breadcrumb" }, [
          _c(
            "li",
            { staticClass: "breadcrumb-item" },
            [
              _c(
                "router-link",
                { attrs: { id: "'breadcrumb0", to: { name: "AgentList" } } },
                [
                  _c("span", { attrs: { to: "" } }, [
                    _vm._v(_vm._s(_vm.$t("MANAGEMENT.MANAGEMENT")))
                  ])
                ]
              )
            ],
            1
          ),
          _c("li", { staticClass: "breadcrumb-item" }, [
            _vm.role == "Company"
              ? _c("span", [_vm._v(_vm._s(_vm.$t("MANAGEMENT.SHAREHOLDER")))])
              : _c("span", [
                  _vm._v(_vm._s(_vm.$t("MANAGEMENT.MEMBERS_AGENTS")))
                ])
          ])
        ])
      ]),
      _c("div", [
        _vm.role == "Company"
          ? _c("h1", [_vm._v(_vm._s(_vm.$t("MANAGEMENT.SHAREHOLDER")))])
          : _c("h1", [_vm._v(_vm._s(_vm.$t("MANAGEMENT.MEMBERS_AGENTS")))]),
        !_vm.dataLoading
          ? _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col-12" }, [
                _c("div", { staticClass: "row mt-2" }, [
                  _c("div", { staticClass: "col-sm-6" }, [
                    _c("nav", { attrs: { "aria-label": "breadcrumb" } }, [
                      _c(
                        "ol",
                        { staticClass: "breadcrumb" },
                        _vm._l(_vm.$store.state.agentPages, function(t, index) {
                          return _c(
                            "li",
                            { key: index, staticClass: "breadcrumb-item" },
                            [
                              index == 0
                                ? _c(
                                    "router-link",
                                    {
                                      attrs: {
                                        id: "breadcrumb" + index,
                                        to: { name: "AgentList" }
                                      }
                                    },
                                    [
                                      _c(
                                        "a",
                                        {
                                          staticClass: "linkNav bold",
                                          staticStyle: {
                                            "text-decoration": "underline"
                                          },
                                          on: {
                                            click: function($event) {
                                              return _vm.goBack(t.id, t.role)
                                            }
                                          }
                                        },
                                        [_vm._v(_vm._s(t.id))]
                                      )
                                    ]
                                  )
                                : index > 0 &&
                                  index < _vm.$store.state.agentPages.length - 1
                                ? _c(
                                    "router-link",
                                    {
                                      attrs: {
                                        id: "breadcrumb" + index,
                                        to: {
                                          name: "AgentList",
                                          query: { id: t.id }
                                        }
                                      }
                                    },
                                    [
                                      _c(
                                        "a",
                                        {
                                          staticClass: "linkNav bold",
                                          staticStyle: {
                                            "text-decoration": "underline"
                                          },
                                          on: {
                                            click: function($event) {
                                              return _vm.goBack(t.id, t.role)
                                            }
                                          }
                                        },
                                        [_vm._v(_vm._s(t.id))]
                                      )
                                    ]
                                  )
                                : _c("a", [
                                    _c(
                                      "a",
                                      {
                                        staticClass: "bold",
                                        staticStyle: {
                                          color: "#cfa137",
                                          "text-decoration": "underline"
                                        },
                                        attrs: { id: "breadcrumb" + index }
                                      },
                                      [_vm._v(_vm._s(t.id))]
                                    )
                                  ])
                            ],
                            1
                          )
                        }),
                        0
                      )
                    ])
                  ]),
                  _c(
                    "div",
                    { staticClass: "col-sm-6" },
                    [
                      _vm.paginateShow
                        ? _c("paginate", {
                            attrs: {
                              "page-count": _vm.pageSize,
                              "page-range": 3,
                              "prev-text": "<<",
                              "next-text": ">>",
                              "click-handler": _vm.clickCallback,
                              "container-class": "pagination float-right",
                              "prev-class": "inline",
                              "next-class": "inline",
                              "page-class": "inline"
                            }
                          })
                        : _vm._e()
                    ],
                    1
                  )
                ]),
                _c("div", { staticClass: "table-responsive" }, [
                  _c(
                    "table",
                    { staticClass: "table table-bordered table-hover" },
                    [
                      _c("thead", [
                        _c("tr", [
                          _c("th", [_vm._v(_vm._s(_vm.$t("MANAGEMENT.NO")))]),
                          _c("th", [
                            _vm._v(_vm._s(_vm.$t("MANAGEMENT.POSITION")))
                          ]),
                          _c("th", [
                            _vm._v(_vm._s(_vm.$t("MANAGEMENT.LOGIN_NAME")))
                          ]),
                          _c("th", [_vm._v(_vm._s(_vm.$t("MANAGEMENT.NAME")))]),
                          _c("th", { staticStyle: { "text-align": "right" } }, [
                            _vm._v(
                              " " + _vm._s(_vm.$t("MANAGEMENT.CREDIT")) + " "
                            )
                          ]),
                          _c("th", [
                            _vm._v(_vm._s(_vm.$t("MANAGEMENT.FIGHT_PT")))
                          ]),
                          _c("th", [
                            _vm._v(_vm._s(_vm.$t("MANAGEMENT.PHONE")))
                          ]),
                          _vm.$store.state.agentPages.length == 1 &&
                          (_vm.subAccountMenus.agent || _vm.mainUser)
                            ? _c("th", [
                                _vm._v(
                                  " " + _vm._s(_vm.$t("MANAGEMENT.EDIT")) + " "
                                )
                              ])
                            : _vm._e(),
                          _c("th", [
                            _vm._v(_vm._s(_vm.$t("MANAGEMENT.STATUS")))
                          ]),
                          _c("th", [
                            _vm._v(_vm._s(_vm.$t("MANAGEMENT.BUTTON.SUSPEND")))
                          ]),
                          _c("th", [
                            _vm._v(_vm._s(_vm.$t("MANAGEMENT.BUTTON.LOCK")))
                          ]),
                          _c("th", [
                            _vm._v(_vm._s(_vm.$t("MANAGEMENT.CURRENCY")))
                          ]),
                          _c("th", [
                            _vm._v(_vm._s(_vm.$t("MANAGEMENT.LAST_LOGIN_DATE")))
                          ]),
                          _c("th", [
                            _vm._v(_vm._s(_vm.$t("MANAGEMENT.LAST_LOGIN_IP")))
                          ])
                        ])
                      ]),
                      _vm.dataLoading
                        ? _c("tbody", [_vm._m(0)])
                        : _c(
                            "tbody",
                            [
                              _vm.agentData.length == 0
                                ? [
                                    _c("tr", [
                                      _c("td", { attrs: { colspan: "11" } }, [
                                        _vm._v(_vm._s(_vm.$t("DATA_NOT_FOUND")))
                                      ])
                                    ])
                                  ]
                                : _vm._l(_vm.agentData.metadata, function(r) {
                                    return _c(
                                      "tr",
                                      {
                                        key: r.id,
                                        class: {
                                          inactive: r.status === "INACTIVE"
                                        }
                                      },
                                      [
                                        _c("td", [_vm._v(_vm._s(r.no + 1))]),
                                        _c("td", [
                                          _c("span", [_vm._v(_vm._s(r.roles))]),
                                          r.typeAgentAPI && r.roles == "Agent"
                                            ? _c("span", [
                                                _vm._v(
                                                  " (" +
                                                    _vm._s(r.typeAgentAPI) +
                                                    ") "
                                                )
                                              ])
                                            : _vm._e()
                                        ]),
                                        _c(
                                          "td",
                                          [
                                            r.roles == "Member"
                                              ? _c("span", [
                                                  _vm._v(
                                                    _vm._s(r.username) + " "
                                                  )
                                                ])
                                              : _c(
                                                  "router-link",
                                                  {
                                                    staticClass: "overflow",
                                                    attrs: {
                                                      to: {
                                                        name: "AgentList",
                                                        query: {
                                                          id: r.username
                                                        }
                                                      }
                                                    }
                                                  },
                                                  [
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass: "link",
                                                        attrs: {
                                                          id:
                                                            "viewChild_" +
                                                            r.username
                                                        },
                                                        on: {
                                                          click: function(
                                                            $event
                                                          ) {
                                                            return _vm.getAgentById(
                                                              r.username,
                                                              r.roles
                                                            )
                                                          }
                                                        }
                                                      },
                                                      [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(r.username) +
                                                            " "
                                                        )
                                                      ]
                                                    )
                                                  ]
                                                )
                                          ],
                                          1
                                        ),
                                        _c("td", [_vm._v(_vm._s(r.name))]),
                                        _c(
                                          "td",
                                          {
                                            staticStyle: {
                                              "text-align": "right"
                                            }
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm._f("currency")(
                                                    r.creditLimit
                                                      ? r.creditLimit
                                                      : 0
                                                  )
                                                ) +
                                                " "
                                            )
                                          ]
                                        ),
                                        _c("td", [
                                          _c("span", [
                                            _c(
                                              "button",
                                              {
                                                staticClass:
                                                  "btn btn-yellow font-weight-bold m-0 px-3 py-2 z-depth-0 waves-effect btnMenu",
                                                staticStyle: {
                                                  "line-height":
                                                    "0rem !important",
                                                  padding:
                                                    "12px 20px 15px 20px !important",
                                                  "font-size": "0.775rem"
                                                },
                                                attrs: {
                                                  type: "button",
                                                  id:
                                                    "viewRoyalty_" + r.username
                                                },
                                                on: {
                                                  click: function($event) {
                                                    return _vm.openModal(
                                                      "sharePercent",
                                                      r.royaltySetting
                                                    )
                                                  }
                                                }
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.$t("PAYMENT.VIEW")
                                                    ) +
                                                    " "
                                                )
                                              ]
                                            )
                                          ])
                                        ]),
                                        _c("td", [
                                          Object.prototype.hasOwnProperty.call(
                                            r,
                                            "information"
                                          )
                                            ? _c("span", [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      r.information.contact
                                                        .phone
                                                    ) +
                                                    " "
                                                )
                                              ])
                                            : _vm._e()
                                        ]),
                                        _vm.$store.state.agentPages.length ===
                                          1 &&
                                        (_vm.subAccountMenus.agent ||
                                          _vm.mainUser)
                                          ? _c(
                                              "td",
                                              [
                                                r.roles == "Member"
                                                  ? _c(
                                                      "router-link",
                                                      {
                                                        attrs: {
                                                          to: {
                                                            name: "EditMember",
                                                            query: {
                                                              id: r.username
                                                            }
                                                          },
                                                          id:
                                                            "edit_" + r.username
                                                        }
                                                      },
                                                      [
                                                        _c("i", {
                                                          staticClass:
                                                            "fas fa-pencil-alt"
                                                        })
                                                      ]
                                                    )
                                                  : _c(
                                                      "router-link",
                                                      {
                                                        attrs: {
                                                          to: {
                                                            name: "EditAgent",
                                                            query: {
                                                              id: r.username
                                                            }
                                                          },
                                                          id:
                                                            "edit_" + r.username
                                                        }
                                                      },
                                                      [
                                                        _c("i", {
                                                          staticClass:
                                                            "fas fa-pencil-alt"
                                                        })
                                                      ]
                                                    )
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                        _c("td", [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                r.active == true
                                                  ? _vm.$t(
                                                      "MANAGEMENT.BUTTON.ACTIVE"
                                                    )
                                                  : _vm.$t(
                                                      "MANAGEMENT.BUTTON.LOCK"
                                                    )
                                              ) +
                                              " "
                                          )
                                        ]),
                                        _c("td", [
                                          _vm._v(
                                            _vm._s(
                                              r.suspend == true
                                                ? _vm.$t("YES")
                                                : _vm.$t("NO")
                                            )
                                          )
                                        ]),
                                        _c("td", [
                                          _vm._v(
                                            _vm._s(
                                              r.lock == true
                                                ? _vm.$t("YES")
                                                : _vm.$t("NO")
                                            )
                                          )
                                        ]),
                                        _c("td", [_vm._v(_vm._s("THB"))]),
                                        _c("td", [
                                          _vm._v(
                                            _vm._s(
                                              _vm._f("dateTime")(r.lastLogin)
                                            )
                                          )
                                        ]),
                                        _c("td", [_vm._v(_vm._s(r.ipAddress))])
                                      ]
                                    )
                                  })
                            ],
                            2
                          )
                    ]
                  )
                ])
              ])
            ])
          : _vm._e()
      ]),
      _c(
        "modal",
        {
          attrs: {
            name: "sharePercentModal",
            width: "700",
            height: "600",
            clickToClose: false
          }
        },
        [
          _c("div", { staticClass: "modal-header" }, [
            _c(
              "h5",
              { staticClass: "modal-title col-sm-11" },
              [
                _c("center", [
                  _vm._v(" " + _vm._s(_vm.$t("MANAGEMENT.GAME_SETTING")) + " ")
                ])
              ],
              1
            ),
            _c(
              "button",
              {
                staticClass: "close",
                attrs: { type: "button", id: "close-sharePercent" },
                on: {
                  click: function($event) {
                    return _vm.hide("share_percent")
                  }
                }
              },
              [_c("span", { attrs: { "aria-hidden": "true" } }, [_vm._v("×")])]
            )
          ]),
          _c(
            "div",
            {
              staticClass: "modal-body",
              staticStyle: { height: "60vh", "overflow-y": "auto" }
            },
            _vm._l(_vm.gameTypeModalVal, function(value, index) {
              return _c("div", { key: index }, [
                _c("div", { staticClass: "row" }, [
                  _c("div", { staticClass: "col-sm-12" }, [
                    value.productId == "ALLIN"
                      ? _c("div", { staticClass: "form-group row" }, [
                          _c(
                            "h5",
                            {
                              attrs: {
                                id: "gamesetting_title_" + value.productId
                              }
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(_vm.$t("GAME_TYPE.ALLIN_OR_FOLD")) +
                                  " "
                              )
                            ]
                          )
                        ])
                      : value.productId == "HOLDEM"
                      ? _c("div", { staticClass: "form-group row" }, [
                          _c(
                            "h5",
                            {
                              attrs: {
                                id: "gamesetting_title_" + value.productId
                              }
                            },
                            [
                              _vm._v(
                                " " + _vm._s(_vm.$t("GAME_TYPE.HOLDEM")) + " "
                              )
                            ]
                          )
                        ])
                      : value.productId == "SIXPLUS"
                      ? _c("div", { staticClass: "form-group row" }, [
                          _c(
                            "h5",
                            {
                              attrs: {
                                id: "gamesetting_title_" + value.productId
                              }
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(_vm.$t("GAME_TYPE.SIX_PLUS_HOLDEM")) +
                                  " "
                              )
                            ]
                          )
                        ])
                      : value.productId == "PLO4"
                      ? _c("div", { staticClass: "form-group row" }, [
                          _c(
                            "h5",
                            {
                              attrs: {
                                id: "gamesetting_title_" + value.productId
                              }
                            },
                            [
                              _vm._v(
                                " " + _vm._s(_vm.$t("GAME_TYPE.PLO4")) + " "
                              )
                            ]
                          )
                        ])
                      : value.productId == "PLO5"
                      ? _c("div", { staticClass: "form-group row" }, [
                          _c(
                            "h5",
                            {
                              attrs: {
                                id: "gamesetting_title_" + value.productId
                              }
                            },
                            [
                              _vm._v(
                                " " + _vm._s(_vm.$t("GAME_TYPE.PLO5")) + " "
                              )
                            ]
                          )
                        ])
                      : _vm._e()
                  ])
                ]),
                _c("div", { staticClass: "row" }, [
                  _c("div", { staticClass: "col-sm-6" }, [
                    _c("div", { staticClass: "form-group row" }, [
                      _c("div", { staticClass: "col-1" }),
                      _c("label", { staticClass: "bold" }, [
                        _vm._v(" Status ")
                      ]),
                      _c("div", { staticClass: "col-8" }, [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: value.enable,
                              expression: "value.enable"
                            }
                          ],
                          staticClass: "form-check-input",
                          attrs: { type: "radio", name: "", value: "true" },
                          domProps: { checked: _vm._q(value.enable, "true") },
                          on: {
                            change: function($event) {
                              return _vm.$set(value, "enable", "true")
                            }
                          }
                        }),
                        _c(
                          "label",
                          {
                            staticClass: "form-check-label bold",
                            staticStyle: { cursor: "not-allowed" },
                            attrs: {
                              for: "",
                              id: value.productId + "_status_enable"
                            }
                          },
                          [_vm._v(" Enable ")]
                        ),
                        _vm._v(" "),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: value.enable,
                              expression: "value.enable"
                            }
                          ],
                          staticClass: "form-check-input",
                          attrs: { type: "radio", name: "", value: "false" },
                          domProps: { checked: _vm._q(value.enable, "false") },
                          on: {
                            change: function($event) {
                              return _vm.$set(value, "enable", "false")
                            }
                          }
                        }),
                        _c(
                          "label",
                          {
                            staticClass: "form-check-label bold",
                            staticStyle: { cursor: "not-allowed" },
                            attrs: {
                              for: "",
                              id: value.productId + "_status_disable"
                            }
                          },
                          [_vm._v(" Disable ")]
                        )
                      ])
                    ]),
                    _c("div", { staticClass: "form-group row" }, [
                      _c("div", { staticClass: "col-1" }),
                      _c("label", {}, [
                        _vm._v(
                          " " +
                            _vm._s(_vm.$t("MANAGEMENT.HOLD_PERCENTAGE")) +
                            " "
                        )
                      ]),
                      _c(
                        "label",
                        { attrs: { id: value.productId + "_hold_rate" } },
                        [
                          _vm._v(
                            " : " +
                              _vm._s(value.receiveRate - value.rate) +
                              "% "
                          )
                        ]
                      )
                    ]),
                    _c("div", { staticClass: "form-group row" }, [
                      _c("div", { staticClass: "col-1" }),
                      _c("label", {}, [
                        _vm._v(
                          " " +
                            _vm._s(_vm.$t("MANAGEMENT.GIVE_PERCENTAGE")) +
                            " "
                        )
                      ]),
                      _c(
                        "label",
                        { attrs: { id: value.productId + "_give_rate" } },
                        [_vm._v(" : " + _vm._s(value.rate) + "%")]
                      )
                    ]),
                    value.productId == "HOLDEM"
                      ? _c("div", { staticClass: "form-group row" }, [
                          _c(
                            "label",
                            { staticClass: "col-4 bold col-form-label" },
                            [_vm._v(" Bet on Flop ")]
                          ),
                          _c("div", { staticClass: "col-8" }, [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: value.miniGameBOF,
                                  expression: "value.miniGameBOF"
                                }
                              ],
                              staticClass: "form-check-input",
                              attrs: {
                                type: "radio",
                                id: "status_holdem_bof1",
                                name: "status_holdem_bof",
                                value: "true"
                              },
                              domProps: {
                                checked: _vm._q(value.miniGameBOF, "true")
                              },
                              on: {
                                change: function($event) {
                                  return _vm.$set(value, "miniGameBOF", "true")
                                }
                              }
                            }),
                            _c(
                              "label",
                              {
                                staticClass: "form-check-label bold",
                                attrs: { for: "" }
                              },
                              [_vm._v(" Enable ")]
                            ),
                            _vm._v(" "),
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: value.miniGameBOF,
                                  expression: "value.miniGameBOF"
                                }
                              ],
                              staticClass: "form-check-input",
                              attrs: {
                                type: "radio",
                                id: "status_holdem_bof2",
                                name: "status_holdem_bof",
                                value: "false"
                              },
                              domProps: {
                                checked: _vm._q(value.miniGameBOF, "false")
                              },
                              on: {
                                change: function($event) {
                                  return _vm.$set(value, "miniGameBOF", "false")
                                }
                              }
                            }),
                            _c(
                              "label",
                              {
                                staticClass: "form-check-label bold",
                                attrs: { for: "" }
                              },
                              [_vm._v(" Disable ")]
                            )
                          ])
                        ])
                      : _vm._e()
                  ]),
                  _c(
                    "div",
                    { staticClass: "col-sm-6" },
                    [
                      _c("div", { staticClass: "mb-4" }, [
                        _c("h4", { staticClass: "bold" }, [
                          _vm._v(_vm._s(_vm.$t("MANAGEMENT.LIMIT_SETTING")))
                        ])
                      ]),
                      _vm._l(_vm.allLimitList[value.productId], function(
                        t,
                        index
                      ) {
                        return _c("div", { key: index, staticClass: "row" }, [
                          _c("div", { staticClass: "col-sm-12" }, [
                            _c("div", { staticClass: "form-group row" }, [
                              _c("div", { staticClass: "col-11" }, [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: value.limit,
                                      expression: "value.limit"
                                    }
                                  ],
                                  staticClass: "chk",
                                  attrs: {
                                    type: "checkbox",
                                    disabled: true,
                                    id: value.productId + "_checkbox_" + index
                                  },
                                  domProps: {
                                    value: t.tierValue,
                                    checked: Array.isArray(value.limit)
                                      ? _vm._i(value.limit, t.tierValue) > -1
                                      : value.limit
                                  },
                                  on: {
                                    change: function($event) {
                                      var $$a = value.limit,
                                        $$el = $event.target,
                                        $$c = $$el.checked ? true : false
                                      if (Array.isArray($$a)) {
                                        var $$v = t.tierValue,
                                          $$i = _vm._i($$a, $$v)
                                        if ($$el.checked) {
                                          $$i < 0 &&
                                            _vm.$set(
                                              value,
                                              "limit",
                                              $$a.concat([$$v])
                                            )
                                        } else {
                                          $$i > -1 &&
                                            _vm.$set(
                                              value,
                                              "limit",
                                              $$a
                                                .slice(0, $$i)
                                                .concat($$a.slice($$i + 1))
                                            )
                                        }
                                      } else {
                                        _vm.$set(value, "limit", $$c)
                                      }
                                    }
                                  }
                                }),
                                _c(
                                  "label",
                                  {
                                    staticClass: "form-check-label bold",
                                    staticStyle: { cursor: "not-allowed" }
                                  },
                                  [_vm._v(" " + _vm._s(t.name) + " ")]
                                )
                              ])
                            ])
                          ])
                        ])
                      })
                    ],
                    2
                  )
                ])
              ])
            }),
            0
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("tr", [
      _c(
        "td",
        { staticStyle: { "text-align": "center" }, attrs: { colspan: "7" } },
        [
          _c("div", { staticClass: "d-flex justify-content-center" }, [
            _c(
              "div",
              {
                staticClass: "spinner-border text-primary",
                attrs: { role: "status" }
              },
              [_c("span", { staticClass: "sr-only" }, [_vm._v("Loading...")])]
            )
          ])
        ]
      )
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }