import { Component, Vue } from "vue-property-decorator";
import { VuejsDatatableFactory } from "vuejs-datatable";
import { GET_CHILDREN_LIST_BY_ID } from "../../../../api/account"
import { FLOD_LIMIT_LIST, HOLDEM_LIMIT_LIST, SIXPLUS_HOLDEMLIMIT_LIST, PLO4_LIMIT_LIST, PLO5_LIMIT_LIST } from "../Component/gameTypeLimit.component"
import moment from 'moment';
import Model from "@/models/Model";
import VModal from 'vue-js-modal'

Vue.use(VuejsDatatableFactory);
Vue.use(VModal, { componentName: 'modal' })
@Component({
    filters: {
        currency(value: any) {
            const val = (value / 1).toFixed(2).replace('.', '.')
            return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
        },
        dateTime(val: any) {
            return val ? moment(val).local().format('DD-MM-YYYY HH:mm:ss') : ''
        }
    }
})
class AgentList extends Vue {
    $axios: any;
    public agentData: any = []
    private model: Model;
    private cookieData: any = Vue.$cookies.get("login")
    private paginate = {
        page: 1,
        limit: 100
    }
    public pageSize = 0;
    public paginateShow = false;
    public dataLoading = false;
    private roleName = ""
    public mainUser = true
    public subAccountMenus = {
        agent: false
    }

    public allLimitList = {
        ALLIN: FLOD_LIMIT_LIST,
        HOLDEM: HOLDEM_LIMIT_LIST,
        SIXPLUS: SIXPLUS_HOLDEMLIMIT_LIST,
        PLO4: PLO4_LIMIT_LIST,
        PLO5: PLO5_LIMIT_LIST
    }

    public gameTypeModalVal: any = {}

    public role = this.$store.state.profile.roles

    constructor() {
        super();
        this.model = new Model();
    }

    async mounted() {
        if (Object.prototype.hasOwnProperty.call(this.cookieData, 'mainUsername')) {
            this.mainUser = false
            this.$store.state.profile.subAccountMenus.filter(val => {
                if (val.name == 'MENU_AGENT') {
                    if (val.permission == 'readonly') {
                        this.subAccountMenus.agent = false
                    } else if (val.permission == 'editable') {
                        this.subAccountMenus.agent = true
                    }
                }
            });
        } else {
            this.mainUser = true
        }

        this.addRouteName()
        this.$store.commit('resetAgent', []);
        this.dataLoading = true
        if (Object.prototype.hasOwnProperty.call(this.$route.query, 'id')) {
            this.$router.push({ name: 'AgentList' })
        }
        this.roleName = this.cookieData.roles[0]
        await this.getAgentDataById(this.cookieData.realUsername, this.cookieData.roles[0], this.paginate.page, this.paginate.limit)
        this.dataLoading = false
    }

    public goBack(id: string, role: string) {
        this.dataLoading = true
        this.roleName = role
        const rawReport = this.$store.state.agentPages.findIndex((val: any) => val.id == id)
        const newReport = this.$store.state.agentPages.slice(0, rawReport + 1)
        this.paginate.page = 1
        this.$store.commit("updateAgent", newReport)
        this.getAgentDataById(id, this.roleName, this.paginate.page, this.paginate.limit)
        this.dataLoading = false
    }

    public async getAgentById(id: string, role: string) {
        this.dataLoading = true
        this.paginate.page = 1;
        this.roleName = role;
        await this.getAgentDataById(id, role, this.paginate.page, this.paginate.limit)
        this.dataLoading = false
    }

    private addRouteName() {
        this.model.Name = this.$route.name;
        this.$store
            .dispatch("addRouteName", this.model)
    }

    public async clickCallback(number: number) {
        const id = this.$route.query.id
        this.paginate.page = number
        if (id) {
            await this.getAgentDataById((id).toString(), this.roleName, this.paginate.page, this.paginate.limit)
        } else {
            await this.getAgentDataById(this.cookieData.realUsername, this.roleName, this.paginate.page, this.paginate.limit)
        }
    }

    private async getAgentDataById(user: string, role: string, page: number, limit: number) {
        let lists = []
        const loader = this.$loading.show({
            color: '#CFA137',
            loader: 'spinner',
            width: 145,
            height: 235,
            backgroundColor: '#000000',
            opacity: 0.5,
        });
        lists = await GET_CHILDREN_LIST_BY_ID(user, page, limit)
        this.agentData = lists[0];
        const report = this.$store.state.agentPages.find((val: any) => val.id == user);
        if (typeof report == 'undefined') {
            this.$store.commit('addAgent', { id: user, role: role });
        }
        if (this.agentData.metadata.length >= 1) {
            const num = (this.paginate.page * this.paginate.limit)
            for (let i = 0, k = num; i < this.agentData.metadata.length; i++, k++) {
                this.agentData.metadata[i].no = k - this.paginate.limit
            }
            if (this.agentData.pageInfo[0].count > this.paginate.limit) {
                this.paginateShow = true
            }
            this.pageSize = this.agentData.pageInfo[0].size;
        } else {
            this.agentData = []
            this.paginateShow = false
        }
        loader.hide()
    }

    public openModal(field: string, data?: any){

        switch (field) {
            case 'sharePercent':
                if(data.products){
                    this.gameTypeModalVal = data.products
                    this.gameTypeModalVal.holdem.miniGameBOF = data.products.holdem.miniGame?.includes('BOF') || false
                }
                this.$modal.show('sharePercentModal');
                break;
        }
        
    }

    public hide(field: string) {
        switch (field) {
            case 'share_percent':
                this.$modal.hide('sharePercentModal');
                break;
            
        }

    }

}

export default AgentList